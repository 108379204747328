<template>
  <div  class="minWidth1000">
    <custom-actions show-back>
      <el-form class="mt30 form" :model="form" :rules="rules" ref="form" label-width="140px">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="form.roleName" placeholder="请输入角色名称"/>
        </el-form-item>
        <el-form-item label="角色描述" prop="description">
          <el-input type="textarea" v-model="form.description" placeholder="请输入角色描述"/>
        </el-form-item>
        <el-form-item label="排序号" prop="orderNum">
          <el-input v-model="form.orderNum" placeholder="请输入排序号"/>
        </el-form-item>
        <el-form-item label="数据权限">
          <el-radio-group v-model="form.dataPermission">
            <el-radio label="1">所有数据</el-radio>
            <el-radio label="2">本人基站数据</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="菜单">
          <div class="items">
            <div class="item">
              <div class="header">菜单权限</div>
              <div class="content">
                <el-tree
                  ref="menuTree"
                  node-key="menuId"
                  :default-checked-keys="form.menuIds"
                  check-strictly
                  default-expand-all
                  show-checkbox
                  :data="menus"
                  :props="{ label: 'menuName', children: 'childNode'}"
                  @node-click="onMenuClick">
                  <template v-slot="{ data }">
                    <div class="org-item">
                      <span>{{data.menuName}}</span>
                      <i class="el-icon-lollipop" :class="{ visible: data.button && data.button.length }"></i>
                    </div>
                  </template>
                </el-tree>
              </div>
            </div>
            <div class="item">
              <div class="header">按钮权限</div>
              <div class="content">
                <el-checkbox v-if="buttonList && buttonList.length" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <div class="mt15"></div>
                <el-checkbox @change="handlecheckedPermissionChange" v-model="item.isSelect" v-for="(item, index) in buttonList" :label="item.butCode" :key="index">{{item.butName}}</el-checkbox>
                <!-- <el-checkbox-group @change="handlecheckedPermissionChange">
                </el-checkbox-group> -->
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit" class="global-button">保存</el-button>
        </el-form-item>
      </el-form>
    </custom-actions>
  </div>
</template>

<script>
import CustomActions from '../../components/CustomActions.vue'
export default {
  components: { CustomActions },
  name: 'RoleAdd',
  data () {
    return {
      roles: [],
      menus: [],
      form: {
        roleName: null,
        description: null,
        orderNum: null,
        parentRoleId: '',
        dataPermission: '2',
        menuList: []
      },
      rules: {
        roleName: [{ required: true, message: '请输入角色名称' }]
      },
      checkAll: false,
      isIndeterminate: false,
      buttonList: []
    }
  },
  computed: {
    roleId () {
      return this.$route.query.roleId
    }
  },
  created () {
    // this.loadMenus()
    this.loadMenuByRoleId()
    this.loadRoles().then(() => {
      if (this.roleId) {
        this.loadDetails()
      }
    })
  },
  methods: {
    loadRoles () {
      return this.$axios.get(this.$apis.system.selectSysRoleByList).then(res => {
        this.roles = res
      })
    },
    loadMenus () {
      this.$axios.get(this.$apis.system.selectSysMenuByList).then(res => {
        this.menus = res
      })
    },
    loadDetails () {
      this.$axios.get(this.$apis.system.selectSysRoleByRoleId, {
        params: { roleId: this.roleId }
      }).then(res => {
        this.form = res
      })
    },
    loadMenuByRoleId () {
      this.$axios.get(this.$apis.system.selectSysMenuById, {
        params: { roleId: this.roleId }
      }).then(res => {
        this.menus = res
      })
    },
    onMenuClick (data) {
      this.buttonList = data.button || []
      this.checkAll = this.buttonList.every(item => item.isSelect === true)
      this.isIndeterminate = this.buttonList.some(item => !item.isSelect)
    },
    handleCheckAllChange (val) {
      this.buttonList.forEach(item => {
        item.isSelect = val
      })
      this.isIndeterminate = false
    },
    handlecheckedPermissionChange (value) {
      this.checkAll = this.buttonList.every(item => item.isSelect === true)
      this.isIndeterminate = this.buttonList.some(item => !item.isSelect)
    },
    submit () {
      const nodes = this.$refs.menuTree.getCheckedNodes(false, true)
      console.log('nodes', nodes)
      this.form.menuList = nodes.map(node => {
        const button = node.button || []
        return {
          menuId: node.menuId,
          button: button.filter(item => item.isSelect)
        }
      })
      this.$refs.form.validate(valid => {
        if (valid) {
          const url = this.roleId ? this.$apis.system.updateSysRole : this.$apis.system.insertSysRole
          this.$axios.post(url, this.form).then(() => {
            this.$router.back()
          })
        }
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.form {
  width: 600px;
}

.org-item {
  display: flex;
  align-items: center;

  i {
    font-size: 14px;
    margin-left: 3px;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  span {
    line-height: 15px;
    font-size: 13px;
    text-decoration: underline;
  }
}

.items {
  display: flex;
  width: 100%;

  .item {
    flex: 1;
    border: 1px solid #eee;

    &:nth-child(2) {
      margin-left: 10px;
    }

    .header {
      background-color: #eee;
      margin-bottom: 10px;
      padding: 3px 10px;
      margin-top: -1px;
    }

    .content {
      padding-left: 10px;
    }
  }
}
</style>
